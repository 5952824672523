import { IMenuItem, MenuItem } from '@Shared/models/IMenuItem';

const _configMainMenu: IMenuItem[] = [
  {
    label: 'Administration',
    icon: 'fa fa-cogs',
    id: 'MA1',
    link: '/admin',
    subs: [
      {
        label: 'Users',
        icon: 'fa fa-users',
        subs: [
          { label: 'User', link: '/admin/user', icon: 'fa fa-user' },
          { label: 'User Menu', link: '/admin/menus', icon: 'fa fa-users-gear' },
          { label: 'User Group', link: '/admin/userGroup', icon: 'fa fa-users-rectangle' },
        ],
      },
      {
        label: 'Release Management',
        icon: 'fa fa-newspaper',
        subs: [
          { label: 'Create Release', link: '/admin/release/create', icon: 'fa fa-plus' },
          { label: 'Find Release', link: '/admin/release/find', icon: 'fa fa-magnifying-glass' },
        ],
      },
      {
        label: 'Suggestion',
        icon: 'fa fa-comments',
        subs: [
          { label: 'Create Suggestion', link: '/admin/suggestion/create', icon: 'fa fa-plus' },
          { label: 'Find Suggestion', link: '/admin/suggestion/find', icon: 'fa fa-magnifying-glass' },
        ],
      },
      {
        label: 'Companies',
        icon: 'fa fa-institution',
        link: '/admin/company',
      },
      {
        label: 'Projects',
        icon: 'fa fa-briefcase',
        subs: [
          { label: 'Create Project', link: '/admin/project/create', icon: 'fa fa-plus' },
          { label: 'Find Project', link: '/admin/project/find', icon: 'fa fa-magnifying-glass' },
        ],
      },
    ],
  },
  {
    label: 'Libraries',
    icon: 'fa fa-book',
    subs: [
      {
        label: 'Administrator',
        icon: 'fa fa-user-astronaut',
        subs: [
          {
            label: 'Find API Library',
            link: '/api',
          },
          {
            label: 'Create API Library',
            link: '/api/create',
          },
          {
            label: 'Find Database Library',
            link: '/db',
          },
          {
            label: 'Create Database Library',
            link: '/db/create',
          },
          {
            label: 'Find FAQ Library',
            link: '/faq',
          },
          {
            label: 'Create FAQ Library',
            link: '/faq/create',
          },
        ],
      },
      {
        label: 'Client',
        icon: 'fa fa-user-tie',
        subs: [
          {
            label: 'API Library',
            link: '/api/client/show',
            // id: 'MVAL2',
          },
          {
            label: 'API Library New',
            link: '/client-libraries/api',
            // id: 'MVFL4',
          },
          {
            label: 'Database Library',
            link: '/db/client',
            // id: 'MVDL3',
          },
          {
            label: 'Database Library New',
            link: '/client-libraries/db',
            // id: 'MVFL4',
          },
          {
            label: 'FAQ Library',
            link: '/client/faq',
            // id: 'MVFL4',
          },
          {
            label: 'FAQ Library New',
            link: '/client-libraries/faq',
            // id: 'MVFL4',
          },
        ],
        // id: 'MC3',
      },
    ],
    // id: 'ML2',
  },
  {
    label: 'Help Content ',
    icon: 'fa fa-book',
    subs: [
      {
        label: 'Content Bank',
        // icon: 'fa fa-user-astronaut',
        link: '/cms/bank',
      },
      {
        label: 'Create Content Resource',
        // icon: 'fa fa-user-astronaut',
        link: '/cms/create',
      },
      {
        label: 'Find Content Resource',
        // icon: 'fa fa-user-astronaut',
        link: '/cms',
      },
    ],
    // id: 'ML2',
  },
  {
    label: 'Reports',
    icon: 'evoicon evo-blankdoc',
    subs: [
      {
        label: 'Create/Run',
        link: '/reports',
        icon: 'fa fa-plus',
      },
      {
        label: 'Batch',
        link: '/reports/batch',
        icon: 'fa fa-server',
      },
    ],
    // id: 'MR5',
  },
  {
    label: 'Analytics',
    icon: 'fa fa-chart-bar',
    subs: [
      {
        label: 'Team Performance',
        link: '/analytics/team-performance',
        icon: 'fa fa-ranking-star',
      },
      {
        label: 'Task O/S SLA',
        link: '/analytics/task-os-sla',
        icon: 'fa fa-list-check',
      },
    ],
  },
  {
    label: 'Set Up',
    icon: 'fa fa-gears',
    subs: [
      { label: 'Code Setup', link: '/setup/codes/parameters', icon: 'fa fa-diagram-project' },
      { label: 'Task Setup', link: '/task/setup', icon: 'fa fa-gear' },
      {
        label: 'Task Process Codes',
        // id: 'MTS7',
        link: '/setup/process-codes/index',
        icon: 'fa fa-bars-progress',
      },
      {
        label: 'Platforms',
        icon: 'fa fa-layer-group',
        subs: [
          {
            label: 'Application Home',
            link: '/application',
          },
          {
            label: 'Find Application',
            link: '/application/find',
          },
        ],
      },
    ],
    // id: 'MSU4',
  },
  {
    label: 'Workflows',
    icon: 'fas fa-network-wired',
    subs: [
      {
        label: 'Bulk Create Workflow',
        link: '/task/client/workflow/bulk-create-workflow',
        icon: 'fas fa-list',
      },
      {
        label: 'Create Workflow',
        link: '/task/client/workflow/create',
        icon: 'fas fa-plus',
      },
      {
        label: 'Find Workflow',
        link: '/task/client/workflow/find',
        icon: 'fa fa-magnifying-glass',
      },
      {
        label: 'Create Schedule',
        link: '/task/workflow/schedule/create',
        icon: 'fas fa-plus',
      },
      {
        label: 'Find Schedule',
        link: '/task/workflow/schedule/find',
        icon: 'fa fa-magnifying-glass',
      },
      {
        label: 'Log Error',
        icon: 'fas fa-bug',
        link: '/task/client/workflow/logs',
      },
      /*       {
        label: 'Administrator',
        subs: [
          {
            label: 'Create',
            subs: [
              {
                label: 'Bulk Create Workflow',
                link: '/task/workflow/bulk-create-workflow',
              },
              {
                label: 'Create Task',
                link: '/task/workflow/create',
              },
              {
                label: 'Create Schedule',
                link: '/task/workflow/schedule/create',
              },
            ],
          },
          {
            label: 'Find',
            subs: [
              {
                label: 'Find Task',
                link: '/task/workflow/find',
              },
              {
                label: 'Find Schedule',
                link: '/task/workflow/schedule/find',
              },
            ],
          },
        ],
      },
      {
        label: 'Client',
        subs: [
          {
            label: 'Bulk Create Workflow',
            link: '/task/client/workflow/bulk-create-workflow',
          },
          {
            label: 'Create New Task',
            link: '/task/client/workflow/create',
          },
          {
            label: 'Find Task',
            link: '/task/client/workflow/find',
          },
        ],
      }, */
    ],
    // id: 'MSU4',
  },
];

export const configMainMenu = _configMainMenu?.map((m) => new MenuItem(m)).sort2('label', true);

// console.log('MENUITEMS', configMainMenu);
// debugger
for (const moduleMenu of configMainMenu) {
  //moduleMenu.icon = undefined;
  moduleMenu.subs.forEach((m) => {
    //m.icon = undefined;
    m['subsLength'] = m.subs?.length || 0;
  });
  moduleMenu.subs = moduleMenu.subs.sort2('label', true);
  moduleMenu.subs = moduleMenu.subs.sort2('subs', false, true);
  // debugger;
  for (let i = 0; i < moduleMenu?.subs?.length; i++) {
    const mi = moduleMenu?.subs[i];
    mi.submenuPosition = moduleMenu?.subs?.length / 2 > i ? 'after' : 'before';
  }
  moduleMenu.systemIcon = moduleMenu.icon;
}
// let j = 1;
// function getID(m: MenuItem) {
//   return (
//     'M' +
//     m.label
//       ?.split(' ')
//       ?.map((x) => x[0])
//       ?.join('') +
//     j++
//   );
// }
// function idier(m: any) {
//   m.id = getID(m);
//   if (m.subs)
//     for (const menu of m.subs) {
//       idier(menu);
//     }
// }
// for (const m of configMainMenu) {
//   idier(m);
// }
